@font-face {
	font-family: 'MyriadPro';
	font-weight: 400;
	src: url('./fonts/myriadpro/MYRIADPRO-REGULAR.OTF') format('opentype');
}

@font-face {
	font-family: 'MyriadPro';
	src: url('./fonts/myriadpro/MYRIADPRO-SEMIBOLD.OTF') format('opentype');
	font-weight: 500;
}

@font-face {
	font-family: 'MyriadPro';
	font-weight: 700;
	src: url('./fonts/myriadpro/MYRIADPRO-BOLD.OTF') format('opentype');
}

@import "vendor/override";
//@import "vendor/bootstrap";
@import "vendor/bootstrap/bootstrap";
@import "vendor/regular";
@import "utilities";
@import "form";
@import "header";
@import "home";
@import "page";
@import "footer";
@import "responsive";
@import "404";
