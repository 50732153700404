$font-family-base: "MyriadPro", sans-serif;

$grid-breakpoints: (
        xs: 0,
        sm: 400px,
        md: 800px,
        lg: 1200px,
        xl: 1600px
);

$container-max-widths: (
        sm: 364px,
        md: 752px,
        lg: 1168px,
        xl: 1543px,
);